@import "variables";
// 共通で使用するボタンのスタイルなどはこちらに記述します

.btn-custom {
  background-color: black;
  color: $theme-accent-white;
  border-radius: 50vh;
  padding: 3px 20px;

  &:hover {
    background-color: $theme-accent-darkgray;
  }
}

.common-btn {
  width: 200px;
  height: 40px;
  border-radius: 50vh;
  line-height: 2.7;
  text-align: center;
  margin: 0 auto;

  &:hover {
    text-decoration: none;

    + img {
      opacity: 0.5;
    }
  }
}

// ボタン色
.done-btn {
  border: none;
  color: $theme-accent-white;
  background-color: $theme-accent-yellow;

  &:hover {
    color: $theme-accent-white;
    background-color: $theme-accent-lightyellow;
  }
}

.done-clear-btn {
  border: 1px solid $theme-accent-yellow;
  color: $theme-accent-yellow;
  background-color: white;

  &:hover {
    color: $theme-accent-lightyellow;
    border: 1px solid $theme-accent-lightyellow;
  }
}

.back-btn {
  border: 1px solid $theme-accent-gray;
  color: $theme-accent-gray;
  background-color: white;

  &:hover {
    color: $theme-accent-lightgray;
    border: 1px solid $theme-accent-lightgray;
  }
}

.top-btn {
  border: none;
  color: $theme-accent-white;
  background-color: $theme-accent-black;

  &:hover {
    color: $theme-accent-white;
    background-color: $theme-accent-darkgray;
  }
}

.disabled-btn {
  border: none;
  color: $theme-accent-white;
  background-color: $theme-accent-lightgray;

  &:hover {
    text-decoration: none;

    + img {
      opacity: 1;
    }
  }
}

.round-btn {
  border-radius: 30px !important;
}

//ボタン
.disabled-gray {
  background-color: #CECECE!important;
  cursor: default!important;
  border: none!important;
  color: white!important;

  :hover {
    background-color: #CECECE!important;
    cursor: default!important;
    border: none!important;
    color: white!important;
  }
}

@media (max-width: 767px) {
  .common-btn {
    width: 100%;
    height: 50px;
    border-radius: 2px;
    line-height: 1;
    text-align: center;
    margin: 0 auto;
    min-height: 45px !important;

    &:hover {
      text-decoration: none;
    }
  }
}


