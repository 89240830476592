@import "variables";
// 共通で使用するボタンのスタイルなどはこちらに記述します
header {
    z-index: 10;
}

main {
    flex: 1;
    padding-top: 105px;
    min-height: 60vh;

    // ヘッダーメニュー押下時の背景グレーアウト
    #header-overflow-area,
    #scroll-header-overflow-area {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        opacity: 0.5;
        background-color: rgba(0, 0, 0, 0.8);
        transition: all 0.5s;
    }
}

body {
    font-family: "Noto Sans JP", "Noto Sans", sans-serif, "源ノ角ゴシック",
        "Meiryo";
    font-size: 0.9rem;
    color: $theme-accent-gray;
    line-height: 2;
    display: flex;
    flex-flow: column;

    .section-title {
        color: black;
        font-weight: bold;
    }

    .error {
        display: flex;
        align-items: center;
    }

    .error_icon {
        background-image: url("../../public/assets/icons/error.svg");
        width: 18px;
        height: 18px;
        display: inline-block;
    }

    .has-error {
        border: 1px solid $red !important;
        border-radius: 5px;
        background-color: #e3342f29;
    }

    .error_message {
        margin: 0;
        display: inline-block;
        padding-left: 5px;
    }

    .information-message {
        width: 100%;
        margin: 20px auto 0;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 $shadow;
        background-color: $theme-main-white;
        font-weight: bold;
        padding: 20px 30px;
    }

    // パンくず
    .breadcrumb {
        padding: 0.75rem 1rem;
        background: none;
        font-size: 0.8rem;
        width: 90%;
        max-width: 1280px;
        margin: 0 auto 1.7rem;
        color: #979797;

        ::-webkit-scrollbar {
            display: none;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            overflow-x: scroll;
            white-space: normal;

            li {
                list-style: none;
                display: flex;

                a {
                    color: $theme-accent-gray;
                    text-decoration: none;
                    white-space: nowrap;

                    &:hover {
                        color: $theme-accent-black;
                    }
                }

                .step {
                    padding: 0 10px;
                }

                span {
                    white-space: nowrap;

                    &:last-child {
                        overflow: hidden;
                    }
                }
            }
        }
    }

    dl {
        dt {
            font-weight: normal !important;
        }
    }

    .no-display {
        display: none;
    }

    .no-display-banner {
        display: none !important;
        opacity: 0;
        transition: all 0.3s;
        animation-name: fadeOut;
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }

    //LINE友達追加バナー
    .line_banner {
        position: relative;
        position: fixed;
        right: 5px;
        z-index: 10;
        .close_btn {
            position: absolute;
            z-index: 3;
            width: 15px;
            height: auto;
            cursor: pointer;
        }
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
    .line_banner_pc {
        text-align: right;
        bottom: 5px;
        .line_banner_img {
            width: 300px;
            height: auto;
            cursor: pointer;
        }
        .line_banner_img:hover {
            filter: brightness(115%) invert(0%);
        }
        .close_btn {
            top: 15px;
            right: 15px;
        }
        .add_btn {
            display: block;
        }
    }

    .line_banner_sp {
        text-align: center;
        bottom: -1px;
        .line_banner_img {
            width: 90%;
            height: auto;
        }
        .close_btn {
            top: 8%;
            right: 10%;
        }
        .add_btn {
            position: absolute;
            bottom: 7%;
            width: 80%;
            text-align: center;
            right: 10%;
            left: 10%;
            background-color: #ffffff;
            padding: 10px 0;
            font-weight: bold;
            border-radius: 5px;
            font-size: 1.2rem;
            color: #5e5e5e;
        }
    }

    // コンテンツ
    .container-fluid {
        width: 90%;
        max-width: 1280px;

        .content {
            padding-right: 0;
        }

        .main-content {
            width: 95%;
            margin: 0 auto;
        }

        // 会員画面用共通レイアウト
        .member-content {
            padding-left: 50px;
            border-left: 1px solid #757575;

            .member-content-aria {
                margin-top: 30px;
            }
        }

        .page-title {
            margin-bottom: 50px;

            h5 {
                color: $theme-accent-black;
                font-size: 1.5rem;
                font-weight: normal;
                margin-bottom: 10px;
                word-break: break-all;
            }

            p {
                font-size: 0.8rem;
            }
        }

        .top-button-aria {
            margin-bottom: 20px;

            a {
                input {
                    border-radius: 10px;
                    width: 8em;
                    margin-right: 10px;
                }
            }
        }

        .bottom-button-aria {
            width: 100%;
            text-align: center;
            margin-top: 50px;
            display: flex;
            justify-content: center;

            a {
                margin: 0 10px;
            }
        }

        .page {
            text-align: center;
            margin-top: 40px;
            letter-spacing: 2px;
            font-size: 13px;

            hr {
                border-width: 2px;
            }

            p {
                display: inline-block;

                span {
                    padding: 0 10px;
                }
            }
        }
    }
}

footer {
    margin-top: 50px;

    .container-fluid {
        width: 100%;
    }
}

// 二重サブミット防止用
#loader-bg {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(175, 175, 175, 0.5);
    z-index: 990;
}

#loader {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
    text-align: center;
    color: #000000;
    z-index: 999;
}

// 背景グレーアウト
.overflow-area {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 1;
    background-color: $modal-back;
    transition: all 0.5s; /*アニメーション設定*/
}

/////////////// 共通部品関連 ///////////////
// トップへ戻るボタン
#page-top {
    display: none;
    width: 80px;
    height: 80px;
    position: fixed;
    right: 0;
    bottom: 50px;
    z-index: 10;
    cursor: pointer;
}

.badge-icon {
    position: absolute;
    background-color: #e40000;
    border-radius: 50%;
    font-size: 1em;
    line-height: 1;
    width: 24px;
    height: 24px;
    padding: 4px;
    color: white;
    text-align: center;
    box-shadow: 0 0 2px #999;
}

.next-page-icon {
    position: absolute;
    width: 10px;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

// 必須バッジ
.required {
    font-size: 12.8px;
    margin: 12px 0 10px 8px;
    padding: 0 15px;
    background-color: $theme-badge-green;
    color: $theme-main-white;
    border-radius: 8px;
    height: 23px;
    line-height: 23px;
}

// input
input[type="text"],
input[type="number"] {
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid $theme-accent-lightgray;
    font-size: 16px;
}

input[type="text"]::placeholder {
    color: $theme-accent-lightgray;
}

// type=numberのスピンボタンを非表示
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}

input[type="password"] {
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #cecece;
    font-size: 16px;
}

select {
    border-radius: 6px !important;
    border: 1px solid $theme-accent-lightgray !important;
}

.disabled-text {
    background-color: #e9ecef;
}

// ラジオボタン
input[type="radio"] + label {
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    width: auto;
    text-align: left;
}

input[type="radio"] + label::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border: 1px solid $theme-accent-gray;
    border-radius: 50vh;
    background-color: white;
}

input[type="radio"].has-error + label::before {
    border: 1px solid #e3342f;
    background-color: #e3342f29;
}

input[type="radio"]:checked + label::after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border: 6px solid $theme-accent-yellow;
    border-radius: 50vh;
}

// チェックボックス
.check-original {
    display: none;
}

.check-original + label {
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    width: auto;
    text-align: left;
}

.check-original + label::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border: 1px solid $theme-main-lightgray;
    background-color: $theme-main-lightgray;
    border-radius: 3px;
}

.check-original:checked + label::after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border: 1px solid $theme-accent-yellow;
    background-color: $theme-accent-yellow;
    border-radius: 3px;
}

.check {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 14px;
    height: 9px;
    margin-top: -7px;
    top: 50%;
    left: 3px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: $theme-main-white;
    z-index: 1;
}

// リンク
a {
    color: $theme-accent-gray;
    text-decoration: none;
}

a:hover {
    color: $theme-accent-gray;
    text-decoration: none;
}

a.search-zipcode {
    color: black;
    cursor: pointer;
    display: block;
    margin-bottom: 20px;
}

// アコーディオン
.accordion {
    ul {
        padding-left: 0;

        li {
            list-style: none;

            a {
                display: block;
                cursor: pointer;
            }
        }

        //２階層以下は初期表示非表示
        ul {
            display: none;
        }
    }
}

// 画像のグレーアウト
img.gray {
    filter: grayscale(0.9);
}

/////////////// カラー関連 ///////////////
//区切り線
hr {
    margin: 15px 0;
    border-color: $theme-accent-lightgray;
}

// 背景色
.bg-gray {
    background: $theme-main-gray;
}

.bg-lightgray {
    background: $theme-accent-lightgray;
}

/////////////// 文字関連 ///////////////
// 文字サイズ
.font-sm {
    font-size: 0.6rem;
}

.font-mdsm {
    font-size: 0.8rem;
}

.font-md {
    font-size: 1rem;
}

.font-lg {
    font-size: 1.1rem;
}

.font-xl {
    font-size: 1.2rem;
}

.hover-opacity:hover {
    opacity: 0.5;
    img {
        opacity: 0.5;
    }
}

// 文字色
.black {
    color: $theme-accent-black !important;
}

.red {
    color: $theme-accent-red !important;
}

.green {
    color: $theme-accent-green !important;
}

.gray {
    color: $theme-accent-gray;
}

.darkgray {
    color: $theme-accent-darkgray;
}

.white {
    color: $theme-accent-white;
}

/////////////// スクロールバー ///////////////
/*スクロールバー全体*/
::-webkit-scrollbar {
    height: 4px;
    width: 10px;
}

/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
    background-color: rgb(83 83 83 / 50%);
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
}

/////////////////////////////////////////////////
/////////////// タブレット対応 ///////////////
@media (max-width: 1020px) and (min-width: 768px) {
    .main-content {
        width: 95% !important;
    }

    .page-title {
        h5 {
            font-size: 1.3rem !important;
            word-break: break-all;
        }
    }

    // 会員画面用共通レイアウト
    .member-content {
        padding-left: 25px !important;
        border-left: 1px solid #757575;

        .member-content-aria {
            margin-top: 30px;
        }
    }

    // トップへ戻るボタン
    #page-top {
        display: none !important;
    }
}

/////////////////////////////////////////////////
/////////////// スマートフォン対応 ///////////////
@media (max-width: 767px) {
    input[type="text"] {
        font-size: 16px;
    }

    main {
        padding-top: 0 !important;
    }
    body {
        .breadcrumb {
            padding: 0.75rem 0;
            font-size: 11px;
            flex-wrap: nowrap;
            width: 90%;
            margin: 0 auto 1rem;
            color: #979797;
        }

        .container-fluid {
            width: 90%;
            margin: 0 auto;

            .content {
            }

            .main-content {
                width: 95% !important;
            }

            // 会員画面用共通レイアウト
            .member-content {
                padding-left: 0;
                border-left: none;

                .member-content-aria {
                    margin-top: 30px;
                }
            }

            .page-title {
                margin-bottom: 15px;

                h5 {
                    font-size: 1.3rem !important;
                    margin-bottom: 10px;
                    word-break: break-all;
                }

                p {
                    font-size: 0.8rem;
                }
            }

            .bottom-button-aria {
                margin: 40px auto 0;
                text-align: center;
                width: 100% !important;
                flex-direction: column;

                a {
                    height: 50px;
                    display: block;
                    margin: 10px;
                }

                // スマホ並び順変更用
                .order1 {
                    order: 1;
                }

                .order2 {
                    order: 2;
                }
            }
        }
    }

    footer {
        .container-fluid {
            width: 100%;
        }
    }

    // トップへ戻るボタン
    #page-top {
        display: none !important;
    }

    img {
        max-width: 100%;
    }
}
